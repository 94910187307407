//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ar } from "vue2-datepicker/locale/ar";
import { en } from "vue2-datepicker/locale/en";
import SharesInvestedComponent from '@/components/SharesInvested/SharesInvested.vue'
import * as moment from "moment";
export default {
    name: 'ContactUs',
    components: { DatePicker, ar, en, SharesInvestedComponent },
    data: function () {
        let self = this;
        return {
            baseUrl: process.env.VUE_APP_APIEndPoint,
            showValidMessage: false,
            successmsg: self.$t('savedSucess'),
            warnmesg: self.$t('warnmsg'),
            ViewMode: 1,
            model: {
                
            },
            filterModel: {
                InvestmentDate: moment(new Date()).subtract(1, "years").subtract(1, "days").locale("en").format('YYYY-MM-DD HH:mm'),
                InvestmentExpireDate: moment(new Date()).subtract(1, "days").locale("en").format('YYYY-MM-DD HH:mm')
            },
            isCheck: false,
            showPopup: false,
            isCumulativeChangeChart: true
        }
    },
    methods: {
        moveDiv: function (isCumulativeChangeChart) {
            var self = this;
            if (isCumulativeChangeChart)
                self.isCumulativeChangeChart = true;
            else
                self.isCumulativeChangeChart = false;

            self.LoadChartData();
        },
        colsePopup: function () {
            this.showPopup = false;
        },
        openTab: function (evt, tabName) {
            var self = this;
            self.tabName = tabName;
            // Declare all variables
            var i, tabcontent, tablinks;

            // Get all elements with class="tabcontent" and hide them
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }

            // Get all elements with class="tablinks" and remove the class "active"
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }

            // Show the current tab, and add an "active" class to the button that opened the tab
            document.getElementById(tabName).style.display = "block";
            evt.currentTarget.className += " active";
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },
        onSubmit: function () {
            alert("Any thing");
            console.log("Any thing");
        },
        disableDate(date) {
            return date > new Date();
        },

        showWedgit: function () {
            let self = this;
            self.isCheck = true;
        },
        notify: function (message, fn = "error") {
            let self = this;
            self.$snotify.clear();
            if (fn == "success") {
                self.$snotify.success(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else if (fn == "warning") {
                self.$snotify.warning(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else {
                self.$snotify.html(message, {
                    type: "error",
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        ShowLoading: function () {
            $("body").addClass("active-loader");
        },
        HideLoading: function () {
            $("body").removeClass("active-loader");
        },
        ConvertToJson: function (str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return "";
            }
            return JSON.parse(str);
        },
        displayError: function (err) {
            var self = this;
            if (err?.response?.status == "401") {
                return;
            }
            if (err?.response?.data?.type && err?.response?.data?.type.toLowerCase() == "business") {
                //let messages = err?.response?.data?.message;
                var messages = self.ConvertToJson(err?.response?.data?.message);
                if (messages != "") {
                    let errorstr = "";
                    messages.map(x => errorstr += self.$t(x.MemberNames) + ' ' + self.$t(x.ErrorMessage) + "<br/>");
                    self.notify(errorstr, "error")
                }
                else {
                    self.notify(self.$t("ConnectionError"), "error");
                }
            }
            else if (err?.response?.data?.type && err?.response?.data?.type.toLowerCase() == "error") {
                var errorMsg = err?.response?.data?.message;
                self.notify(self.$t(errorMsg[Object.keys(errorMsg)[0]]) + "<br/>" + err?.response?.data?.exCode, "error");
            }
            else if (err.length > 0) {
                for (let i = 0; i < err.length; i++) {
                    let errorstr = "";
                    errorstr += err[i].Message + "<br/>";
                    self.notify(errorstr, "error");
                }
            }
            else {
                self.notify(self.$t("ConnectionError"), "error");
            }
        },
        LoadChartData: function () {
            let self = this;
            self.ShowLoading();

            self.filterModel.InvestmentDate = moment(self.filterModel.InvestmentDate).locale("en").format('YYYY-MM-DD HH:mm');
            self.filterModel.InvestmentExpireDate = moment(self.filterModel.InvestmentExpireDate).locale("en").format('YYYY-MM-DD HH:mm');

            self.filterModel.IsCumulativeChangeChart = self.isCumulativeChangeChart;

            self.axios
                .post(process.env.VUE_APP_APIEndPoint + "InvestmentCalculator/Calculater", self.filterModel)
                .then(function (res) {
                    self.showWedgit();

                    self.model = res.data.Data;

                    self.model.ChangePercentage = Math.round(self.model.ChangePercentage * 100) / 100;//self.model.ChangePercentage.toString().slice(0, 5);
                    self.filterModel.InvestedAmount = self.model.ValueFromAfterConvert;

                    self.model.InvestmentDate = moment(self.model.InvestmentDate).locale(self.$i18n.locale == "ar" ? "ar-SA" : "en").format('LL');
                    self.model.InvestmentExpireDate = moment(self.model.InvestmentExpireDate).locale(self.$i18n.locale == "ar" ? "ar-SA" : "en").format('LL');

                    let data = res.data.Data.ShareChartData;

                    // split the data set into totalAmount, changeAmountPlus and changeAmountMins
                    var totalAmount = [];
                    var changeAmountPlus = [];
                    var changeAmountMins = [];
                    var dataLength = data.length;
                    //var dataLength = data.length;
                    let i = 0;

                    for (i; i < dataLength; i += 1) {
                        totalAmount.push([
                            data[i]['data'][0], // the date
                            data[i]['data'][1], // totalAmount
                        ]);

                        changeAmountPlus.push([
                            data[i]['data'][0], // the date
                            data[i]['data'][2], // changeAmountPlus
                        ]);

                        changeAmountMins.push([
                            data[i]['data'][0], // the date
                            data[i]['data'][3], // changeAmountMins
                        ]);
                    }

                    self.chart = Highcharts.stockChart('containerCumulativeChange', {
                        rangeSelector: {
                            enabled: false,
                        },
                        stockTools: {
                            gui: {
                                enabled: false // disable the built-in toolbar
                            }
                        },
                        xAxis: {
                            gridLineWidth: 1,
                        },
                        yAxis: [{
                            opposite: false,
                            labels: {
                                align: 'right',
                                x: -3
                            },
                            title: {
                                text: self.$t('SAR')
                            },
                            lineWidth: 1,
                            shadow: true,
                            resize: {
                                enabled: true
                            }
                            , events: {
                                aftersetextremes: function (e) {
                                    self.drawextrema(e.target.chart);

                                }
                            }
                        }],
                        tooltip: {
                            shape: 'square',
                            headerShape: 'callout',
                            borderWidth: 1,
                            shadow: true,
                            useHTML: true,
                            formatter: function () {
                                let s = '<b>' + Highcharts.dateFormat('%A, %b %e, %Y', this.x) + '</b></br>';

                                if (this.points) {
                                    this.points.forEach(point => {
                                        if (point.series.name == 'Sasco') {
                                            s += '<b><span style = "color:' + point.series.color + ';">' + self.$t('SASCO') + ' </b>'
                                                + ': ' + point.y.toFixed(2) + '</b></br>';
                                        }
                                    });
                                    return s;
                                }
                            }
                        },

                        navigator: {
                            enabled: false
                        },
                        scrollbar: {
                            enabled: false
                        },
                        series: [{
                            type: 'line',
                            id: 'sasco',
                            name: 'Sasco',
                            data: totalAmount,
                        }],
                        responsive: {
                            rules: [{
                                condition: {
                                    maxWidth: 800
                                },
                                chartOptions: {
                                    rangeSelector: {
                                        inputEnabled: false
                                    }
                                }
                            }]
                        }
                    });

                    //call the function initially
                    self.drawEXtrema(self.chart);

                    if (!self.isCumulativeChangeChart) {
                        Highcharts.stockChart('containerPeriodicChange', {
                            rangeSelector: {
                                enabled: false,
                            },
                            stockTools: {
                                gui: {
                                    enabled: false // disable the built-in toolbar
                                }
                            },
                            xAxis: {
                                gridLineWidth: 1,
                            },
                            yAxis: [{
                                opposite: false,
                                labels: {
                                    align: 'right',
                                    x: -3
                                },
                                title: {
                                    text: self.$t('SAR')
                                },
                                lineWidth: 1,
                                shadow: true,
                                resize: {
                                    //enabled: true
                                }
                                , reversedStacks: false
                            }],
                            tooltip: {
                                shape: 'square',
                                headerShape: 'callout',
                                borderWidth: 1,
                                shadow: true,
                                useHTML: true,
                                formatter: function () {
                                    debugger;
                                    let s = '<b>' + Highcharts.dateFormat('%A, %b %e, %Y', this.x) + '</b></br>';


                                    var sascoPoint = this.points.find(x => x.series.name == "Sasco");
                                    var changeAmountPlusPoint = this.points.find(x => x.series.name == "changeAmountPlus");
                                    var changeAmountMinsPoint = this.points.find(x => x.series.name == "changeAmountMins");

                                    s += '<div style=" width:10px ;height: 10px;border: 1px solid' + sascoPoint.series.color + ';backgroundColor:' + sascoPoint.series.color + ';"></div>'
                                        + (changeAmountPlusPoint.y == 0 ? '' : '+<div style=" width:10px ;height: 10px;border: 1px solid' + changeAmountPlusPoint.series.color + ';backgroundColor:' + changeAmountPlusPoint.series.color + ';"></div>')
                                        + '<b>' + self.$t('Value') + '(' + self.$t('SAR') + '): </b>'
                                        + ': ' + (sascoPoint.y + changeAmountPlusPoint.y).toFixed(2) + '</b></br>'

                                        
                                        + '<b>' + self.$t('SharePrice') + ': </b>'
                                        + (changeAmountPlusPoint.y == 0 ?
                                        ((sascoPoint.y).toFixed(2) / self.model.BuyArrows).toFixed(2)
                                        : ((sascoPoint.y + changeAmountPlusPoint.y).toFixed(2) / self.model.BuyArrows).toFixed(2)) + '</b></br>'

                                        + (changeAmountPlusPoint.y == 0 ? '' :
                                         '<div style=" width:10px ;height: 10px;border: 1px solid' + changeAmountPlusPoint.series.color + ';backgroundColor:' + changeAmountPlusPoint.series.color + ';"></div>'
                                        + '<b><span style = "color:' + changeAmountPlusPoint.series.color + ';">' + self.$t('ChangeText') + '(' + self.$t('SAR') + ') </b>'
                                        + ': ' + changeAmountPlusPoint.y.toFixed(2) + '</b></br>'
                                        + '<b>' + self.$t('Change') + ' </b>'
                                        + ': ' + ((changeAmountPlusPoint.y / sascoPoint.y) * 100).toFixed(2) + '</b></br>'
                                        )

                                        + (changeAmountMinsPoint.y == 0 ? '' :
                                        '<div style=" width:10px ;height: 10px;border: 1px solid' + changeAmountMinsPoint.series.color + ';backgroundColor:' + changeAmountMinsPoint.series.color + ';"></div>'
                                        + '<b><span style = "color:' + changeAmountMinsPoint.series.color + ';">' + self.$t('ChangeText') + '(' + self.$t('SAR') + ') </b>'
                                        + ': ' + changeAmountMinsPoint.y.toFixed(2) + '</b></br>'
                                        + '<b>' + self.$t('Change') + ' </b>'
                                        + ': ' + ((changeAmountMinsPoint.y / (changeAmountMinsPoint.y + sascoPoint.y)) * 100).toFixed(2) + '</b></br>'
                                        );

                                    return s;
                                }
                            },
                            plotOptions: {
                                series: {
                                    stacking: 'normal'
                                }
                            },
                            navigator: {
                                enabled: false
                            },
                            scrollbar: {
                                enabled: false
                            },
                            series: [{
                                type: 'column',
                                id: 'sasco',
                                name: 'Sasco',
                                data: totalAmount,
                                color: '#9fa9a3'
                            },
                            {
                                type: 'column',
                                id: 'changeAmountPlus',
                                name: 'changeAmountPlus',
                                data: changeAmountPlus,
                                color: 'green'
                            },
                            {
                                type: 'column',
                                id: 'changeAmountMins',
                                name: 'changeAmountMins',
                                data: changeAmountMins,
                                color: 'red'
                            }
                            ],
                            responsive: {
                                rules: [{
                                    condition: {
                                        maxWidth: 800
                                    },
                                    chartOptions: {
                                        rangeSelector: {
                                            inputEnabled: false
                                        }
                                    }
                                }]
                            }
                        });
                    }






                })
                .catch(function (err) {
                    self.displayError(err);
                    return console.log(err);
                }).finally(function () {
                    return self.HideLoading();
                });

        },
        drawEXtrema: function (chart) {
            let self = this;
            var firstPoint = chart.series[0].points[0];
            var lastPoint = chart.series[0].points[chart.series[0].points.length - 1];


            //add the series to the chart
            chart.addSeries({
                id: 'extHigh',
                type: 'scatter',
                name: 'extHigh',
                color: 'green',
                data: [{ x: chart.series[0].points[chart.series[0].points.length - 1].x, y: chart.series[0].points[chart.series[0].points.length - 1].y }],
                dataLabels: {
                    enabled: true,
                    borderRadius: 5,
                    backgroundColor: 'rgba(252, 255, 197, 0.7)',
                    borderWidth: 1,
                    borderColor: '#AAA',
                    useHTML: true,
                    format: '<b><span style = "color:green;">' + Highcharts.dateFormat('%A, %b %e, %Y', chart.series[0].points[chart.series[0].points.length - 1].x) + '</b></br>' +  self.$t('Last') + ': ' + chart.series[0].points[chart.series[0].points.length - 1].y.toFixed(2) + '</b></br>',
                }
            });
            chart.addSeries({
                id: 'extLow',
                type: 'scatter',
                color: 'red',
                name: 'extLow',
                data: [{ x: chart.series[0].points[0].x, y: chart.series[0].points[0].y }],
                dataLabels: {
                    enabled: true,
                    borderRadius: 5,
                    backgroundColor: 'rgba(252, 255, 197, 0.7)',
                    borderWidth: 1,
                    borderColor: '#AAA',
                    useHTML: true,
                    format: '<b><span style = "border:1px;color:red;">' + Highcharts.dateFormat('%A, %b %e, %Y', chart.series[0].points[0].x) + '</b></br>' +  self.$t('first') + ': ' + chart.series[0].points[0].y.toFixed(2) + '</b></br>'
                }
            });
        },
        save: function () {
            let self = this;
            self.showPopup = true;
            self.showValidMessage = true;
            self.$validator.validate().then(function (result) {
                if (!result) { ////not valid
                    let detail = $('.form-error').first()[0];
                    if (detail) {
                        detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        self.notify(self.$t('warnmsg'), "warning");

                    }
                    return;
                }
                $('.form-error').removeClass("form-error");
                self.LoadChartData();


            });
        },

    },

    mounted() {
        document.getElementById("defaultOpen").click();
    }

};
